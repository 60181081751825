import { ComponentType, createContext, PropsWithChildren } from 'react'
import { ISwapContext, SwapAssetModel, SwapOfferModel } from './types'
import initialState from './state'
import { useSwapService } from './service'
import { IWalletBalance } from '../balance/types'

export const SwapContext = createContext<ISwapContext>({
  state: initialState(),
  getAssets: async (balances: IWalletBalance[]) => {},
  setAssetForSend: (asset: SwapAssetModel) => {},
  setAssetForReceive: (asset: SwapAssetModel) => {},
  replaceAssets: () => {},
  setSendAmount: (value: string) => {},
  estimateSwap: async () => {},
  setOffer: (offer: SwapOfferModel) => {}
})

export const SwapProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useSwapService()

  return <SwapContext.Provider value={service}>{children}</SwapContext.Provider>
}
