import { ComponentType, createContext, PropsWithChildren, useContext } from 'react'
import initialState from './state'
import { IImportWalletBody, ITwoFaBody, IWalletContext } from './types'
import { useWalletService } from './service'

const WalletContext = createContext<IWalletContext>({
  state: initialState(),
  getUserWallets: async () => {},
  createWallet: async () => {},
  selectWallet: (id: string) => {},
  editWallet: async () => {},
  deleteWallet: async (id: string, body: ITwoFaBody) => {},
  getMerchant: async (merchantId: string) => {},
  importWalletBySeedPhrase: async (body: IImportWalletBody) => {}
})

export const WalletProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useWalletService()
  return <WalletContext.Provider value={service}>{children}</WalletContext.Provider>
}
const useWalletContext = () => useContext(WalletContext)

export default useWalletContext
