import { ComponentType, createContext, PropsWithChildren, useContext } from 'react'

import { useWithdrawalService } from './service'
import { 
  IWithdrawalContext, 
  IWithdrawalBody,
  IWithdrawalFeeBody,
  IEstimateMaxBody,
} from './types'
import initialState from './state'

const WithdrawalContext = createContext<IWithdrawalContext>({
  state: initialState(),
  getWithdrawals: async () => {},
  createWithdrawal: async (merchantId: string, walletId: string, body: IWithdrawalBody) => {},
  getFee: async (merchantId: string, walletId: string, body: IWithdrawalFeeBody) => {},
  getEstimateMax: async (merchantId: string, walletId: string, body: IEstimateMaxBody) => {},
})

export const WithdrawalProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useWithdrawalService()
  return <WithdrawalContext.Provider value={service}>{children}</WithdrawalContext.Provider>
}

const useWithdrawalContext = () => useContext(WithdrawalContext)

export default useWithdrawalContext
