import { ComponentType, createContext, PropsWithChildren, useContext } from 'react'

import { useSettingsService } from './service'
import {
  ISettingsContext,
  IProfileBody,
  ITwoFactorBody,
  IEmailResendCodeBody,
  IEmailTwoFaBody,
  IMultiFaBody,
  IMultiFaPrivateKeyBody
} from './types'
import initialState from './state'

const SettingsContext = createContext<ISettingsContext>({
  state: initialState(),
  getProfile: async () => {},
  updateProfile: async (body: IProfileBody) => {},
  verifyChangeEmail: async (secretKey: string) => {},
  getTwoFactor: async () => {},
  resetTwoFactor: async () => {},
  activateTwoFactor: async (body: ITwoFactorBody) => {},
  deactivateTwoFactor: async (twoFactorToken: string) => {},
  emailTwoFaResendCode: async (body: IEmailResendCodeBody) => {},
  activateEmailTwoFa: async (body: IEmailTwoFaBody) => {},
  emailMultiFaResendCode: async (body: IEmailResendCodeBody) => {},
  sendCodeToCurrentEmail: async () => {},
  updateEmailState: (email: string) => {},
  verifyCurrentEmailCode: async (code: string) => {},
  confirmCodeFromNewEmail: async (body: { twoFactorToken?: string }, code: string) => {},
  sendCodeToNewEmail: async () => {},
  changeEmail: async (body: { email: string }) => {},
  confirmMultiFa: async (body: IMultiFaBody) => {},
  getUserPrivateKey: async (
    body: IMultiFaPrivateKeyBody,
    merchantId: string,
    walletId: string
  ) => {}
})

export const SettingsProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useSettingsService()
  return <SettingsContext.Provider value={service}>{children}</SettingsContext.Provider>
}

const useSettingsContext = () => useContext(SettingsContext)

export default useSettingsContext
