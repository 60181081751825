import { useReducer } from 'react'
import useHttpClient from '../http-client'

import initialState from './state'
import reducer from './reducer'
import {
  EActions,
  IEditWalletBody,
  IImportWalletBody,
  IMerchantListParams,
  ITwoFaBody,
  IWalletInfo,
  Wallet
} from './types'

import { requestCreateWallet } from './api/request-create-wallet'

import { ICreateWalletBody } from '@/pages/create-wallet-page/create-wallet/CreateWallet'
import { useStorageContext } from '../storage/hooks/use-storage-context'
import { CURRENT_MERCHANT_ID } from '@/constants/wallets/storage-wallet-key'
import { IApiResponse } from '@/types/api/api.interface'

export const useWalletService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()
  const storage = useStorageContext()

  const getUserWallets = async () => {
    const params: IMerchantListParams = { limit: 100, page: 1 }
    dispatch({ type: EActions.GET })
    try {
      const { data } = await httpClient.get<any>('api/merchant/list', { params })
      dispatch({ type: EActions.GET_SUCCESS, wallets: data.data.merchants })

      if (data.data.merchants.length > 0) {
        setWalletFromStorage(data.data.merchants)
      }
    } catch (error) {
      dispatch({ type: EActions.GET_ERROR })
    }
  }
  const createWallet = async (body: ICreateWalletBody) => {
    const response = await requestCreateWallet(httpClient, body)

    dispatch({ type: EActions.GET_MERCHANT, merchant: response.data.data })
  }

  const setWalletFromStorage = (merchants: Wallet[]) => {
    const id = storage.getItem(CURRENT_MERCHANT_ID)
    const merchant = merchants.find((item) => item._id === id)

    if (merchant) {
      dispatch({ type: EActions.GET_MERCHANT, merchant })
    } else {
      storage.setItem(CURRENT_MERCHANT_ID, merchants[0]._id)

      dispatch({ type: EActions.GET_MERCHANT, merchant: merchants[0] })
    }
  }

  const selectWallet = (id: string) => {
    storage.setItem(CURRENT_MERCHANT_ID, id)

    const merchant = state.wallets.find((item) => item?._id === id)

    if (merchant) {
      dispatch({ type: EActions.GET_MERCHANT, merchant })
    }
  }

  const editWallet = async (merchantId: string, body: IEditWalletBody) => {
    await httpClient.patch<IApiResponse<any>>(`/api/merchant/${merchantId}`, {
      ...body
    })
  }

  const getMerchant = async (merchantId: string) => {
    const { data } = await httpClient.get<IApiResponse<IWalletInfo>>(`/api/merchant/${merchantId}`)
    return data;
  }

  const deleteWallet = async (merchantId: string, body: ITwoFaBody) => {
    await httpClient.delete<IApiResponse<any>>(`/api/merchant/${merchantId}`, {
      data: body
    })
  }
  const importWalletBySeedPhrase = async (body: IImportWalletBody) => {
    try {
      const { data } = await httpClient.post<IApiResponse<any>>(`/api/merchant/import`, body)

      return data
    } catch (error) {
      throw error
    }
  }

  return {
    state,
    createWallet,
    getUserWallets,
    selectWallet,
    editWallet,
    deleteWallet,
    getMerchant,
    importWalletBySeedPhrase
  }
}
