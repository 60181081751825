import { SwapState } from "./types"

const initialState = (): SwapState => ({
  assets: [],
  assetForSend: null,
  assetForReceive: null,
  sendAmount: null,
  estimates: [],
  offer: null
})

export default initialState