import { useReducer } from 'react'
import initialState from './state'
import { reducer } from './reducer'
import useHttpClient from '../http-client'
import { IApiResponse } from '@/types/api/api.interface'
import { ICurrencyResponse } from '@/types/api/swap/currency.interface'
import { parseAssets } from './parsers/parseAssets'
import { EActions, SwapAssetModel, SwapEstimateParams, SwapOfferModel } from './types'
import { IWalletBalance } from '../balance/types'
import { ISwapEsimateItem } from '@/types/api/swap/estimate.interface'

const getEstimateControllers: AbortController[] = []

const rejectAllCurrentGetEstimateRequests = (): void => {
  getEstimateControllers?.forEach?.((controller) => {
    controller?.abort?.()
  })
  getEstimateControllers.length = 0
}

export const useSwapService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const getAssets = async (balances: IWalletBalance[]): Promise<any> => {
    const { data } = await httpClient.get<IApiResponse<ICurrencyResponse>>('api/currency')

    const assets = parseAssets(data.data.currencies, balances)

    dispatch({ type: EActions.GET_ASSETS, assets })
  }

  const setAssetForSend = (asset: SwapAssetModel) => {
    dispatch({ type: EActions.SET_ASSET_FOR_SEND, asset })
  }

  const setAssetForReceive = (asset: SwapAssetModel) => {
    dispatch({ type: EActions.SET_ASSET_FOR_RECEIVE, asset })
  }

  const replaceAssets = () => {
    const forSend = state.assetForSend
    const forReceive = state.assetForReceive

    if (!forSend || !forReceive) return

    dispatch({
      type: EActions.SET_ASSET_FOR_SEND,
      asset: forReceive
    })

    dispatch({
      type: EActions.SET_ASSET_FOR_RECEIVE,
      asset: forSend
    })
  }

  const setSendAmount = (value: string) => {
    dispatch({
      type: EActions.SET_SEND_AMOUNT,
      sendAmount: value
    })
  }

  const estimateSwap = async () => {
    const assetForSend = state.assetForSend
    const assetForReceive = state.assetForReceive
    const sendAmount = state.sendAmount

    if (!assetForSend || !assetForReceive || !sendAmount || +sendAmount === 0) return

    rejectAllCurrentGetEstimateRequests()

    const controller = new AbortController()

    getEstimateControllers.push(controller)

    const params: SwapEstimateParams = {
      fromId: assetForSend.id,
      toId: assetForReceive.id,
      amount: sendAmount,
      sort: 'rate'
    }

    try {
      const { data } = await httpClient.get<IApiResponse<ISwapEsimateItem[]>>('api/swap/estimate', {
        params,
        signal: controller.signal
      })

      dispatch({
        type: EActions.SET_ESTIMATES,
        estimates: data.data
      })
    } catch {}
  }

  const setOffer = (offer: SwapOfferModel) => {
    dispatch({
      type: EActions.SET_OFFER,
      offer
    })
  }

  return {
    state,
    getAssets,
    setAssetForSend,
    setAssetForReceive,
    replaceAssets,
    setSendAmount,
    estimateSwap,
    setOffer
  }
}
