import { useReducer } from 'react'
import initialState from './state'
import { reducer } from './reducer'
import { 
  EActions, 
  IWithdrawalBody,
  IWithdrawalsResponse,
  IWithdrawalFeeBody,
  IWithdrawalFee,
  IEstimateMaxBody,
  IEstimateMaxResponse,
} from './types'
import useHttpClient from '../http-client'
import { IApiResponse } from '@/types/api/api.interface'

export const useWithdrawalService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const getWithdrawals = async (): Promise<any> => {
    const { data } = await httpClient.get<IApiResponse<IWithdrawalsResponse>>('/api/withdrawal')
    dispatch({ type: EActions.GET_WITHDRAWALS, withdrawals: data.data.entities })
  }

  const createWithdrawal = async (merchantId: string, walletId: string, body: IWithdrawalBody) => {
    await httpClient.post<IApiResponse<any>>(`/api/withdrawal/${merchantId}/${walletId}`, body)
  }

  const getFee = async (merchantId: string, walletId: string, body: IWithdrawalFeeBody): Promise<any> => {
    const { data } = await httpClient.post<IApiResponse<IWithdrawalFee>>(`/api/withdrawal/${merchantId}/${walletId}/estimate`, body)
    dispatch({ type: EActions.GET_FEE, fee: data.data })
  }

  const getEstimateMax = async (merchantId: string, walletId: string, body: IEstimateMaxBody): Promise<any> => {
    dispatch({ type: EActions.GET_ESTIMATE_MAX, estimateMax: '' })
    const { data } = await httpClient.post<IApiResponse<IEstimateMaxResponse>>(`/api/withdrawal/${merchantId}/${walletId}/estimateMax`, body)
    dispatch({ type: EActions.GET_ESTIMATE_MAX, estimateMax: data.data.amount })
  }

  return {
    state,
    getWithdrawals,
    createWithdrawal,
    getFee,
    getEstimateMax,
  }
}
