import { EStatus } from '@/constants/common/status'
import { IBalanceState } from './types'

export const initialState: () => IBalanceState = () => ({
  balance: null,
  isHidden: false,
  currencies: null,
  assetTransactions:[],
  getStatus: EStatus.INIT
})
