import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ELanguagesCode, SUPPORTED_LANGUAGES } from '@/constants/languages'

const defaultLng = ELanguagesCode.EN
const storedLng = localStorage.getItem('i18nextLng')

if (!storedLng) {
  localStorage.setItem('i18nextLng', defaultLng)
}

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLng,
    supportedLngs: SUPPORTED_LANGUAGES,
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: 'i18nextLng'
    },
    debug: true,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
