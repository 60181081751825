import { useReducer } from 'react'
import { reducer } from './reducer'
import { initialState } from './state'
import { EActions, IBalanceResponse, ICurrencyRateResponse, IMerchantBalanceParams } from './types'
import useHttpClient from '../http-client'
import { IApiResponse } from '@/types/api/api.interface'
import {
  ITransaction,
  ITransactionGetParams,
  ITransactionResponse
} from '@/types/api/transactions/transaction.interface'

export const useBalanceService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const hide = () => dispatch({ type: EActions.HIDE_BALANCE })

  const show = () => dispatch({ type: EActions.SHOW_BALANCE })

  const getAccountBalance = async (merchantId: string) => {
    const params: IMerchantBalanceParams = {
      typeNetwork: process.env.REACT_APP_TYPE_NETWORK as string
    }
    dispatch({ type: EActions.GET })

    try {
      const { data } = await httpClient.get<IApiResponse<IBalanceResponse>>(
        `api/merchant/${merchantId}/balance`,
        { params }
      )

      dispatch({ type: EActions.GET_SUCCESS, payload: data.data })
    } catch (error) {
      dispatch({ type: EActions.GET_ERROR })
    }
  }

  const getCurrencies = async () => {
    dispatch({ type: EActions.GET })
    try {
      const { data } =
        await httpClient.get<IApiResponse<ICurrencyRateResponse>>(`api/currency/rate`)

      dispatch({ type: EActions.GET_CURRENCIES, payload: data.data })
    } catch (e) {
      dispatch({ type: EActions.GET_ERROR })
    }
  }

  const getAssetTransactions = async (merchantId: string, params: ITransactionGetParams) => {
    return await httpClient.get<IApiResponse<ITransactionResponse>>(
      `/api/transaction/${merchantId}/list`,
      {
        params
      }
    )
  }

  const setAssetTransactions = (assets: ITransaction[]): void => {
    dispatch({ type: EActions.SET_ASSET_HISTORY, transactions: assets })
  }

  return {
    ...state,
    hide,
    show,
    getAccountBalance,
    getCurrencies,
    getAssetTransactions,
    setAssetTransactions
  }
}
