import { EStatus } from '@/constants/common/status'
import { ITransaction, ITransactionGetParams } from '@/types/api/transactions/transaction.interface'
import { IReducerAction } from '@/types/other/state.interface'

export enum EBalanceListType {
  DEPOSIT = 'deposit',
  SEND = 'send'
}
export type BalanceListType = 'deposit' | 'send'

export enum EActions {
  HIDE_BALANCE = 'HIDE_BALANCE',
  SHOW_BALANCE = 'SHOW_BALANCE',
  SET_BALANCE = 'SET_BALANCE',
  GET = 'GET',
  GET_SUCCESS = 'GET_SUCCESS',
  GET_ERROR = 'GET_ERROR',
  GET_BALANCE = 'GET_BALANCE',
  GET_CURRENCIES = 'GET_CURRENCIES',
  SET_ASSET_HISTORY = 'SET_ASSET_HISTORY'
}

export interface IMerchantBalanceParams {
  typeNetwork: string
}

export interface IBalanceState {
  balance: IBalanceResponse | null
  isHidden: boolean
  currencies: ICurrencyRateResponse | null
  assetTransactions: ITransaction[]
  getStatus: EStatus
}

export interface IBalanceTotal {
  usd: number
  btc: number
}

export interface IWalletBalance {
  wallet: string
  walletId: string
  balance: {
    value: string
    usd: number
  }
  currency: IWalletBalanceCurrency
}
export interface IWalletBalanceCurrency {
  id: string
  name: string
  nodeType: string
  blockchainName: string
  title: string
  type: string
}
export interface IBalanceResponse {
  balances: IWalletBalance[]
  total: IBalanceTotal
}

export interface ICurrencyRateResponse {
  [key: string]: number
}

export interface IBalanceContext extends IBalanceState {
  hide: () => void
  show: () => void
  getAccountBalance: (merchantId: string) => void
  getCurrencies: () => void
  getAssetTransactions: (merchantId: string, params: ITransactionGetParams) => Promise<any> | any
  setAssetTransactions: (assets: ITransaction[]) => void
}

export interface IBalanceActionHide extends IReducerAction<EActions.HIDE_BALANCE> {}
export interface IBalanceActionShow extends IReducerAction<EActions.SHOW_BALANCE> {}
export interface IBalanceActionGet extends IReducerAction<EActions.GET> {}
export interface IBalanceActionGetSuccess extends IReducerAction<EActions.GET_SUCCESS> {
  payload: IBalanceResponse
}
export interface IBalanceActionGetCurrencies extends IReducerAction<EActions.GET_CURRENCIES> {
  payload: ICurrencyRateResponse
}
export interface IBalanceActionSetAssetHistory extends IReducerAction<EActions.SET_ASSET_HISTORY> {
  transactions: ITransaction[]
}
export interface IBalanceActionGetError extends IReducerAction<EActions.GET_ERROR> {}

export type Actions =
  | IBalanceActionHide
  | IBalanceActionShow
  | IBalanceActionGet
  | IBalanceActionGetError
  | IBalanceActionGetSuccess
  | IBalanceActionGetCurrencies
  | IBalanceActionSetAssetHistory
