import { EStatus } from '@/constants/common/status'
import { IReducerAction } from '@/types/other/state.interface'

export interface Wallet {
  balanceUSD: number
  createdAt: string
  currencies: string[]
  fee: number
  feeReplenish: number
  feeWallets: string[]
  isCorporate: boolean
  isGetSeedPhrase: boolean
  middleWallet: boolean
  name: string
  payerCommission: string
  payerCommissionReplenish: string
  status: boolean
  typeNetwork: string
  updatedAt: string
  user: string
  _id: string
}

export interface IWalletInfo {
  balanceUSD: number
  createdAt: string
  currencies: string[]
  fee: number
  feeReplenish: number
  feeWallets: string[]
  isGetSeedPhrase: boolean
  mainWallets: {
    currency: string
    wallet: string
  }[]
  middleWallet: boolean
  name: string
  payerCommission: string
  payerCommissionReplenish: string
  status: boolean
  supportUsers: string[]
  typeNetwork: string
  updatedAt: string
  user: string
  _id: string
}

export interface WalletState {
  wallets: Wallet[]
  merchant: any
  getStatus: EStatus
}
export interface IWalletContext {
  state: WalletState
  getUserWallets: () => Promise<any>
  createWallet: (data: any) => Promise<any>
  selectWallet: (id: string) => void
  editWallet: (id: string, body: IEditWalletBody) => Promise<any>
  getMerchant: (id: string) => Promise<any>
  deleteWallet: (id: string, body: ITwoFaBody) => Promise<any>
  importWalletBySeedPhrase: (body: IImportWalletBody) => Promise<any>
}
export const enum EActions {
  GET = 'get',
  GET_SUCCESS = 'getSuccess',
  GET_MERCHANT = 'getMerchant',
  GET_ERROR = 'getError',
  SET_DATA = 'setData'
}

export interface IMerchantListParams {
  limit: number
  page: number
}

export interface IEditWalletBody {
  defaultSetting: boolean
  name: string
  status: boolean
}
export interface IImportWalletBody {
  seedPhrase: string
}

export interface ITwoFaBody {
  emailOtp: string
  twoFactorToken: string
}

export interface IActionGet extends IReducerAction<EActions.GET> {}

export interface IActionGetSuccess extends IReducerAction<EActions.GET_SUCCESS> {
  wallets: any
}
export interface IActionGetSuccess extends IReducerAction<EActions.GET_SUCCESS> {
  wallets: any
}
export interface IActionGetMerchant extends IReducerAction<EActions.GET_MERCHANT> {
  merchant: any
}

export interface IActionGetError extends IReducerAction<EActions.GET_ERROR> {}

export interface IActionSetData extends IReducerAction<EActions.SET_DATA> {
  wallets: any
}
export type Actions =
  | IActionGet
  | IActionGetSuccess
  | IActionGetError
  | IActionSetData
  | IActionGetMerchant
