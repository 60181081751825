import { EStatus } from '@/constants/common/status'
import { Actions, WalletState, EActions } from './types'

const reducer = (state: WalletState, action: Actions): WalletState => {
  switch (action.type) {
    case EActions.GET: {
      return {
        ...state,
        getStatus: EStatus.PROCESSING
      }
    }
    case EActions.GET_SUCCESS: {
      return {
        ...state,
        getStatus: EStatus.SUCCESS,
        wallets: action.wallets
      }
    }
    case EActions.GET_MERCHANT: {
      return {
        ...state,
        merchant: action.merchant
      }
    }
    case EActions.GET_ERROR: {
      return {
        ...state,
        getStatus: EStatus.ERROR
      }
    }
    case EActions.SET_DATA: {
      return {
        ...state,
        wallets: action.wallets
      }
    }
  }
}

export default reducer
