import { ComponentType, createContext, PropsWithChildren } from 'react'
import { useBalanceService } from './service'
import { IBalanceContext } from './types'
import { EStatus } from '@/constants/common/status'
import { ITransaction, ITransactionGetParams } from '@/types/api/transactions/transaction.interface'

export const BalanceContext = createContext<IBalanceContext>({
  isHidden: false,
  balance: null,
  currencies: null,
  getStatus: EStatus.INIT,
  assetTransactions: [],
  hide: () => {},
  show: () => {},
  getAccountBalance: () => {},
  getCurrencies: () => {},
  getAssetTransactions: (merchantId: string, params: ITransactionGetParams) => {},
  setAssetTransactions: (assets: ITransaction[]) => {}
})

export const BalanceProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const value = useBalanceService()

  return <BalanceContext.Provider value={value}>{children}</BalanceContext.Provider>
}
