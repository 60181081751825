import { Actions, EActions, SwapState } from './types'

export const reducer = (state: SwapState, action: Actions) => {
  switch (action.type) {
    case EActions.GET_ASSETS: {
      return {
        ...state,
        assets: action.assets
      }
    }
    case EActions.SET_ASSET_FOR_SEND: {
      return {
        ...state,
        assetForSend: action.asset
      }
    }
    case EActions.SET_ASSET_FOR_RECEIVE: {
      return {
        ...state,
        assetForReceive: action.asset
      }
    }
    case EActions.SET_SEND_AMOUNT: {
      return {
        ...state,
        sendAmount: action.sendAmount
      }
    }
    case EActions.SET_ESTIMATES: {
      return {
        ...state,
        estimates: action.estimates
      }
    }
    case EActions.SET_OFFER: {
      return {
        ...state,
        offer: action.offer
      }
    }
  }
}
