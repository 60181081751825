import { Actions, EActions, WithdrawalState } from './types'

export const reducer = (state: WithdrawalState, action: Actions) => {
  switch (action.type) {
    case EActions.GET_WITHDRAWALS: {
      return {
        ...state,
        withdrawals: action.withdrawals
      }
    }
    case EActions.GET_FEE: {
      return {
        ...state,
        fee: action.fee
      }
    }
    case EActions.GET_ESTIMATE_MAX: {
      return {
        ...state,
        estimateMax: action.estimateMax
      }
    }
  }
}
